import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../layout';
import Navbar from '../navbar.mdx';
import Banner from '../banner.mdx';
import Container from '../container';
export const fullwidth = true;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  fullwidth,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Banner mdxType="Banner" />
    <Container sx={{
      py: 5
    }} mdxType="Container">
      <h2>{`About`}</h2>
      <p>{`System UI is an open source organization that houses a `}<a parentName="p" {...{
          "href": "/theme"
        }}>{`Theme Specification`}</a>{` for creating interoperable UI components.
It is also the home for the `}<a parentName="p" {...{
          "href": "https://theme-ui.com"
        }}>{`Theme UI`}</a>{` and `}<a parentName="p" {...{
          "href": "https://github.com/system-ui/designql"
        }}>{`DesignQL`}</a>{` libraries that are built upon the foundation of this specification.
The intent behind this specification is to help make UI components, libraries, and tools as interoperable as possible.`}</p>
      <p>{`If you'd like to get involved, head over to the `}<a parentName="p" {...{
          "href": "https://github.com/system-ui"
        }}>{`GitHub`}</a>{` project and feel free to comment, open issues, or open PRs.`}</p>
      <h2>{`Who's Using this Specification`}</h2>
      <p>{`The following design systems and libraries currently adhere to the specification. If you'd like to add your project to this list, please `}<a parentName="p" {...{
          "href": "https://github.com/system-ui/system-ui.com"
        }}>{`open a PR`}</a>{`.`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://primer.style/components"
          }}>{`GitHub's Primer Components`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://palette.artsy.net/"
          }}>{`Artsy's Palette`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://sproutsocial.com/seeds/"
          }}>{`Sprout Social's Seeds`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://radix.modulz.app/docs/getting-started/"
          }}>{`Modulz Radix`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://pricelinelabs.github.io/design-system/"
          }}>{`Priceline Design System`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://styled-system.com"
          }}>{`Styled System`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://theme-ui.com"
          }}>{`Theme UI`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://chakra-ui.com/"
          }}>{`Chakra UI`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://rebassjs.org"
          }}>{`Rebass`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://johnpolacek.github.io/styled-system-html/"
          }}>{`Styled System HTML`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/smooth-code/xstyled"
          }}>{`xstyled`}</a></li>
      </ul>
    </Container>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      