import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ThemeProvider, Styled } from 'theme-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <ThemeProvider theme={{
  styles: {
    root: {
      py: 6,
      color: 'white',
      bg: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}}>
    <Styled.root>
      {props.children}
    </Styled.root>
  </ThemeProvider>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://contrast-swatch.daneden.vercel.app/000/fff?size=128&fontSize=2&text=UI",
        "alt": "logo"
      }}></img></p>
    <h1>{`System UI`}</h1>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      